export const tags = [
  {
    text: 'All',
    value: 'all',
    href: ''
  },
  {
    text: 'Domestic',
    value: 'domestic',
    href: '/flights/flights-to-north-america'
  },
  {
    text: 'India',
    value: 'india',
    href: '/flights/flights-to-india'
  },
  {
    text: 'Europe',
    value: 'europe',
    href: '/flights/flights-to-europe'
  },
  {
    text: 'South America',
    value: 'southAmerica',
    href: '/flights/flights-to-south-america'
  },
  {
    text: 'Asia',
    value: 'asia',
    href: '/flights/flights-to-asia'
  },
  {
    text: 'South East Asia',
    value: 'southEastAsia',
    href: '/flights/flights-to-south-east-asia'
  },
  {
    text: 'Middle East',
    value: 'middleEast',
    href: '/flights/flights-to-middle-east'
  },
  {
    text: 'Africa',
    value: 'africa',
    href: '/flights/flights-to-africa'
  },
  {
    text: 'Australia',
    value: 'australia',
    href: '/flights/flights-to-australia'
  }
];

export const canadaTags = [
  {
    text: 'All',
    value: 'all',
    href: '/canada'
  },
  {
    text: 'Canada',
    value: 'domestic',
    href: '/canada/flights/canada'
  },
  {
    text: 'Europe',
    value: 'europe',
    href: '/canada/flights/flights-to-europe'
  },
  {
    text: 'International',
    value: 'international',
    href: '/canada/flights/international'
  },
  {
    text: 'USA',
    value: 'usa',
    href: '/canada/flights/flights-to-north-america'
  }
];
