import * as PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout';
import LowestFlightDeals from '../components/LowestFlightDeals/LowestFlightDeals';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import WhyBookWithUs from '../components/WhyBookWithUs/WhyBookWithUs';
import SEO from '../components/seo';
import { isOnline, isFlykart } from '../helpers/urlParams';
import { callTrackingApiUrl } from '../configs';
import CovidRestrictionPage from './CovidRestrictionPage';
import { graphql } from 'gatsby';

class Marketing extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      landingJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      bImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      isBannersShown: false,
      isBannerApiResponseReceived: false,
      ipAction: ''
    };
  }

  async componentDidMount() {
    const response = await fetch(`${callTrackingApiUrl}/call-tracking/banner`);
    const responseJson = await response.json();

    this.setState({
      isBannersShown: responseJson.data.show,
      isBannerApiResponseReceived: true,
      ipAction: responseJson.data.ipAction
    });
  }

  render() {
    const onlineCampaign = isOnline();
    const flykartTheme = isFlykart();
    const img =
      this.props.data.bImage && onlineCampaign
        ? !flykartTheme
          ? this.props.data.bImage.childImageSharp.fluid
          : this.props.data.fImage.childImageSharp.fluid
        : this.props.data.aImage.childImageSharp.fluid;

    const landingImages = {};

    this.props.data.images.edges.forEach(n => {
      n.node.childImageSharp &&
        (landingImages[n.node.relativePath] = n.node.childImageSharp.fluid);
    });

    const pageData = {
      ...this.props.data.landingJson,
      tag: {
        continent: 'marketing',
        fixedNumber: true
      }
    };

    return (
      <Layout
        title={this.props.data.landingJson.title}
        pageData={pageData}
        passPhoneNumber={() => {}}
        isLanding
        image={img}
        isBannersShown={this.state.isBannersShown}
        isBannerApiResponseReceived={this.state.isBannerApiResponseReceived}
        ipAction={this.state.ipAction}
      >
        <SEO
          title={this.props.data.landingJson.seoTitle}
          description={this.props.data.landingJson.seoDescription}
        />
        <CovidRestrictionPage />
        {flykartTheme && <WhyBookWithUs />}
        <div className="main">
          <LowestFlightDeals
            images={landingImages}
            data={this.props.data.landingJson}
          />
          {flykartTheme && <SubscribeForm />}
        </div>
        <Trustpilot />
        {!flykartTheme && (
          <>
            <div className="main">
              <SubscribeForm />
            </div>
            <WhyBookWithUs />
          </>
        )}
      </Layout>
    );
  }
}

export default Marketing;

export const pageQuery = graphql`
  query($pairs: [String!]) {
    landingJson(name: { eq: "home" }) {
      ...Landing_details
    }

    aImage: file(relativePath: { eq: "flight-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    bImage: file(relativePath: { eq: "Bitmap.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { eq: "flykart-img/flykart-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    images: allFile(filter: { relativePath: { in: $pairs } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
