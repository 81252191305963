import React from 'react';
import './covidAlert.css';
import covidAlert from '../images/covidAlertt.svg';

function CovidRestrictionPage() {
  const URL =
    'https://apply.joinsherpa.com/travel-restrictions?affiliateId=sherpa&language=en-US';

  return (
    <div className="CovidRestrictionParent">
      <div className="CovidRestrictionChild">
        <img src={covidAlert} alt="" />
        <div id="covidAlert">
          <h5>Want to Know About COVID-19 Travel Restrictions?</h5>
          <p>
            Before booking your flight, please check the latest COVID-19 travel
            restrictions to India. Please continuously check all COVID-19
            related restrictions directly with airlines prior to travel as they
            may change.
          </p>
        </div>
        <a target="_blank" id="SeeRestrictionTab" href={URL} rel="noreferrer">
          See Restrictions
        </a>
      </div>
    </div>
  );
}

export default CovidRestrictionPage;
