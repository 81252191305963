import PropTypes from 'prop-types';
import React from 'react';

import LinkUrl from '../LinkUrl';
import Image from '../Image/Image';
import { formatDateCityPair } from '../../helpers/dateHelper';
import { getCurrency } from '../../helpers/currency';
import { setCityPairDates } from '../../helpers/dateHelper';
import { baseIBEURL } from '../../configs';
import { getSearchFlightURL } from '../../helpers/utils';

import './CityPairCard.css';

const CityPairCard = ({
  title,
  startDate,
  endDate,
  price,
  slug,
  image,
  originIATA,
  destinationIATA
}) => {
  let { newStartDate, newEndDate } = setCityPairDates(startDate, endDate);

  const slugIBE = () => {
    const params = {
      origin: originIATA,
      destination: destinationIATA,
      endDate: newEndDate,
      startDate: newStartDate,
      cabinClass: 'ECONOMY',
      passengers: {
        adults: 1
      },
      baseIBEURL,
      activeTabIndex: 0
    };
    return getSearchFlightURL(params);
  };

  return (
    <LinkUrl className="travel-place-card" path={slugIBE()}>
      <div className="image-wrapper">
        {image && <Image image={image} alt="travelImage" />}
      </div>
      <div className="content">
        <div className="city-flight">
          <span>{title}</span>
        </div>
        <div className="date-flight">
          <span className="flights-dates">
            {formatDateCityPair(newStartDate)}
          </span>
          <span className="hyphen">&#8212;</span>
          <span className="flights-dates">
            {formatDateCityPair(newEndDate)}
          </span>
        </div>
        <span className="ticket-price">
          {getCurrency()}
          {price}
        </span>
      </div>
    </LinkUrl>
  );
};

CityPairCard.propTypes = {
  endDate: PropTypes.string,
  image: PropTypes.object.isRequired,
  price: PropTypes.number,
  slug: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  title: PropTypes.string.isRequired
};

CityPairCard.defaultProps = {
  image: {}
};

export default CityPairCard;
