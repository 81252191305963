import React from 'react';
import { isFlykart } from '../../helpers/urlParams';

import './WhyBookWithUs.css';

const WhyBookWithUs = () => {
  const pathImages = isFlykart()
    ? '/../../images/flykart/why-book-with-us/'
    : '/../../images/why-book-with-us/';

  return (
    <div className="with-us-block">
      <h3 className="title">Why Book With Us?</h3>
      <div className="our-positive desktop-wrapper d-flex">
        <div className="block d-flex">
          <div className="wrapper-image d-flex">
            <img
              className="image"
              src={`${pathImages}discountedFares.png`}
              alt="discounted-fares"
            />
          </div>
          <p>Special Negotiated Deals</p>
        </div>
        <div className="block d-flex">
          <div className="wrapper-image d-flex">
            <img
              className="image"
              src={`${pathImages}liveAssistance.png`}
              alt="live-assistance"
            />
          </div>
          <p>24/7 Live Support</p>
        </div>
        <div className="block d-flex">
          <div className="wrapper-image d-flex">
            <img
              className="image"
              src={`${pathImages}safeShopping.png`}
              alt="safe-shopping"
            />
          </div>
          <p>100% Safe Shopping</p>
        </div>
        <div className="block d-flex">
          <div className="wrapper-image d-flex">
            <img
              className="image"
              src={`${pathImages}bestPriceGuaranteed.png`}
              alt="best-price-guaranteed"
            />
          </div>
          <p>Best Price Guarantee</p>
        </div>
      </div>
    </div>
  );
};

export default WhyBookWithUs;
