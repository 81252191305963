import React, { useState } from 'react';
import CityPairCard from '../CityPairCard/CityPairCard';
import { useWindowSize } from '../../helpers/useWindowSize';
import LinkUrl from '../LinkUrl';
import './LowestFlightDeals.css';
import { mobileWidth } from '../../configs/constants';
import { tags, canadaTags } from './tags';
import { getThemeName } from '../../helpers/urlParams';

const mapTags = (isCanada, landingName) => {
  const tagsMap = {
    northAmerica: 'domestic',
    flights: 'domestic',
    cheapFlights: 'domestic',
    cheapFlightsFromUSA: 'domestic',
    cheapFlightsToSouthWestUSA: 'domestic',
    premiumEconomyFlights: 'domestic',
    home: 'all',
    canada: 'domestic',
    flightsToElSalvador: 'domestic',
    flightsToAlaska: 'domestic',
    flightsToSicily: 'europe',
    flightsToWarsaw: 'europe'
  };

  if (isCanada && landingName === 'northAmerica') {
    tagsMap.northAmerica = 'usa';
  }

  return tagsMap[landingName];
};

const LowestFlightDeals = ({ data, images, isCanada }) => {
  const currentTag = mapTags(isCanada, data.name) || data.name;
  const [quantityCityPairs, setQuantityCityPairs] = useState(6);
  const [quantityCityPairsMobile, setQuantityCityPairsMobile] = useState(3);
  const defaultImage = Object.values(images)[0];
  let cityPairlength = 0;
  const [width] = useWindowSize();
  const findCards = currentTag =>
    data.pairs
      .filter((pair, i) => pair.tags.find(tag => tag === currentTag))
      .sort((a, b) => a.price - b.price);

  const showAllDeals = () => {
    setQuantityCityPairs(quantityCityPairs + 3);
    setQuantityCityPairsMobile(quantityCityPairsMobile + 3);
  };
  const getImageSrc = cityPairs => {
    const img = images[`flight-deals/${cityPairs.backgroundImage}`];
    return img ? img : defaultImage;
  };
  const dealTags = isCanada ? canadaTags : tags;
  const dataTheme = getThemeName();
  const initCardsCount = width <= mobileWidth ? 3 : 6;

  return (
    <div className="flight-deals-block" data-theme={dataTheme}>
      <div className="wrapper">
        <h2 id="lowestFlightDealsSection">Our Lowest Flight Deals</h2>
        {width > mobileWidth && (
          <ul className="tabs-categories">
            {dealTags.map((tag, index) => (
              <LinkUrl path={tag.href} key={tag.value + String(index)}>
                <li className={currentTag === tag.value ? 'active' : ''}>
                  {tag.text}
                </li>
              </LinkUrl>
            ))}
          </ul>
        )}
        <div className="wrapper-card d-flex">
          {currentTag === 'all' ? (
            <>
              {data.pairs.map((cityPairs, index) => {
                cityPairlength = index;
                return (
                  index <
                    (width > mobileWidth
                      ? quantityCityPairs
                      : quantityCityPairsMobile) && (
                    <CityPairCard
                      image={getImageSrc(cityPairs)}
                      key={cityPairs.destinationIATA + String(index)}
                      {...cityPairs}
                    />
                  )
                );
              })}
            </>
          ) : (
            <>
              {findCards(currentTag).map((cityPairs, index) => {
                cityPairlength = index;
                return (
                  index <
                    (width > mobileWidth
                      ? quantityCityPairs
                      : quantityCityPairsMobile) && (
                    <CityPairCard
                      image={getImageSrc(cityPairs)}
                      imageMap={images}
                      key={cityPairs.destinationIATA + index}
                      {...cityPairs}
                    />
                  )
                );
              })}
            </>
          )}
        </div>
        {cityPairlength > initCardsCount && quantityCityPairs < 9 && (
          <button className="btn-all-deals" onClick={showAllDeals}>
            See more deals
          </button>
        )}
      </div>
    </div>
  );
};

LowestFlightDeals.propTypes = {};

LowestFlightDeals.defaultProps = {};

export default LowestFlightDeals;
